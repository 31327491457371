<template>
  <div :class="{show: $store.state.task.taskView}" class="task-view">
    <div class="app-fixed-search d-flex align-items-center">
      <!-- Toggler -->
      <div
        class="sidebar-toggle d-flex align-items-center ml-1"
        style="height: 38px"
        @click="$store.commit('task/CHANGE_TASK_VIEW', false)"
      >
        <feather-icon class="cursor-pointer" icon="ChevronRightIcon" size="21" />
      </div>
    </div>
    <div class="container p-0 board-scroll tw-scroll-smooth">
      <div class="tw-p-4">
        <Task is-view-props v-if="$store.state.task.taskView" />
      </div>
    </div>
  </div>
</template>

<script>
import Task from '@/views/Task.vue';

export default {
  components: {
    Task,
  },
};
</script>

<style lang="scss" scoped>
.task-view {
  width: calc(100% - 100px);
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: #fff;
  z-index: 2;
  transform: translateX(100%);
  transition: all 0.25s ease, color 0s;

  .container {
    height: calc(100% - 50px);
  }

  &.show {
    box-shadow: -8px 0px 10px 0px #f4f4f4;
    transform: translateX(0);
    visibility: visible;
    transition: all 0.25s ease, color 0s;
  }
}
</style>
