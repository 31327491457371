<template>
  <div class="position-relative" style="height: inherit">
    <div class="todo-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">
        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon class="cursor-pointer" icon="MenuIcon" size="21" @click="mqShallShowLeftSidebar = true" />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between flex-grow-1">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <b-dropdown no-caret toggle-class="p-0" variant="link">
                <template #button-content>
                  <feather-icon v-if="statusInputQuery" icon="SearchIcon" />
                  <feather-icon v-else icon="SendIcon" />
                </template>
                <b-dropdown-item @click="changeStatusInput(true)">
                  <p class="tw-flex tw-items-center tw-m-0">
                    <feather-icon icon="SearchIcon" />
                    <span class="ml-1">Search Task</span>
                  </p>
                </b-dropdown-item>
                <b-dropdown-item @click="changeStatusInput(false)">
                  <p class="tw-flex tw-items-center tw-m-0">
                    <feather-icon icon="SendIcon" />
                    <span class="ml-1">Quick Task Creation</span>
                  </p>
                </b-dropdown-item>
              </b-dropdown>
            </b-input-group-prepend>
            <b-form-input
              v-model="searchQuery"
              :placeholder="placeholderQuery"
              @input="updateRouteQuery"
              @keydown.enter="quickTaskCreation"
            />
          </b-input-group>
        </div>
        <!-- Dropdown -->
        <div class="dropdown tw-flex tw-items-center tw-gap-x-3">
          <svg
            v-tippy="{content: 'Refresh'}"
            class="tw-w-4 tw-h-4 tw-cursor-pointer hover:tw-text-blue-400 tw-transition tw-outline-none"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            @click="getTasks"
          >
            <path
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <!--          <font-ai-->
          <!--            :icon="['fas', 'chart-simple']"-->
          <!--            class="align-middle icon-color mr-75 text-primary-hover cursor-pointer font-medium-1"-->
          <!--            @click="showModalChart = true"-->
          <!--          />-->

          <b-dropdown no-caret right toggle-class="p-0 mr-1" variant="link">
            <template #button-content>
              <font-ai
                :icon="['fas', 'ellipsis-vertical']"
                class="align-middle icon-color text-primary-hover cursor-pointer font-medium-1"
              />
            </template>
            <b-dropdown-item>Reset Sort</b-dropdown-item>
            <b-dropdown-item :to="{name: $route.name, query: {...$route.query, sort: 'title-asc'}}">
              Sort A-Z
            </b-dropdown-item>
            <b-dropdown-item :to="{name: $route.name, query: {...$route.query, sort: 'title-desc'}}">
              Sort Z-A
            </b-dropdown-item>
            <b-dropdown-item :to="{name: $route.name, query: {...$route.query, sort: 'assignee'}}">
              Sort Assignee
            </b-dropdown-item>
            <b-dropdown-item :to="{name: $route.name, query: {...$route.query, sort: 'due-date'}}">
              Sort Due Date
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <!-- Tasks List -->
      <div class="todo-task-list-wrapper list-group scroll-area">
        <div :class="{show: $store.state.tasks.loading}" class="no-results">
          <os-loader />
        </div>
        <div class="table-tasks todo-task-list media-list ob-scroll">
          <div
            v-for="(task, index) in filterTasks"
            :key="index"
            :class="{completed: isCompleted(task)}"
            @click.self="handleTaskClick(task)"
          >
            <a
              :href="$router.resolve({name: 'task', params: {id: task.id}}).href"
              class="todo-title-wrapper tw-flex tw-items-center tw-gap-x-2"
              @contextmenu.prevent.stop="handleClick($event, task)"
            >
              <div :class="{active: task.is_important}" class="pl-1" @click.prevent="changedImportant(task)">
                <svg
                  :class="task.is_important ? 'text-warning' : 'text-muted'"
                  class="tw-w-5 tw-h-5"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>

              <!--              <div :class="{'d-none': windowWidth < 768}" class="pl-50">-->
              <!--                <b-form-checkbox-->
              <!--                  :checked="isCompleted(task)"-->
              <!--                  style="padding-left: 1.4rem"-->
              <!--                  @change="updateTaskIsCompleted(task)"-->
              <!--                  @click.native.stop-->
              <!--                />-->
              <!--              </div>-->

              <div class="tw-flex tw-gap-x-[2px] tw-items-end">
                <span class="tw-min-w-[5px] tw-h-2 tw-bg-gray-100 tw-rounded-sm" :class="getColorPriority(task, ['low', 'normal', 'high'])"></span>
                <span class="tw-min-w-[5px] tw-h-3 tw-bg-gray-100 tw-rounded-sm" :class="getColorPriority(task, ['normal', 'high'])"></span>
                <span class="tw-min-w-[5px] tw-h-4 tw-bg-gray-100 tw-rounded-sm" :class="getColorPriority(task, ['high'])"></span>
              </div>

              <b-badge
                :variant="`light-${resolveTypeVariant(task.task_type)}`"
                class="tw-select-none"
                @click.self="handleTaskClick(task)"
              >
                <b-spinner v-if="task.id === null" style="height: 12px; width: 12px" />
                <template v-else>
                  {{ '#' + task.id }}
                </template>
              </b-badge>

              <div class="table-tasks__subject" @click.self="handleTaskClick(task)">
                <span
                  v-tippy="{content: task.subject, delay: [1000, 0]}"
                  :class="{
                    'text-muted text-del': isCompleted(task),
                    'text-danger text-lighten-4': task.is_deleted,
                  }"
                  >{{ task.subject }}</span
                >
              </div>

              <b-badge
                v-if="task.project !== null"
                :style="formatColor($store.state.osBoard.projects.find(el => el.id === task.project.id))"
                class="ml-50 badge-link table-tasks__hover-visible font-small-2 text-uppercase"
                @click.prevent="$router.push({name: 'project', query: {id: task.project.id}})"
              >
                {{ task.project.title }}
              </b-badge>

              <div :class="{'d-none': windowWidth < 768}">
                <b-badge
                  :variant="`light-${resolveTypeVariant(task.task_type)}`"
                  class="text-uppercase font-small-2 br-50 table-tasks__hover-visible"
                >
                  {{ task.task_type }}
                </b-badge>
              </div>

              <div :class="{'d-none': windowWidth < 768}">
                <b-badge class="text-uppercase font-small-2 br-50 table-tasks__hover-visible" variant="light-info">
                  {{ formatEstimate(task.estimate) }}
                </b-badge>
              </div>

              <div v-show="task.timer_started">
                <transition id="" name="fade" transition-path="">
                  <div>
                    <img :src="require('@/assets/images/icons/67-clock-outline.gif')" alt="" style="height: 22px" />
                  </div>
                </transition>
              </div>

              <div v-if="task.is_inbox">
                <svg
                  class="tw-w-5 tw-h-5"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661Z"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>

              <b-nav>
                <b-dropdown class="status-button" no-caret variant="link" @show="getStatusesForProject(task.project)">
                  <template #button-content>
                    <b-button
                      :variant="`outline-${colorStatus(task.status.label)}`"
                      class="rounded-lg py-25 px-50 font-small-1 font-weight-bolder"
                      size="sm"
                    >
                      {{ task.status !== null ? task.status.value : 'Reserve' }}
                    </b-button>
                  </template>
                  <b-dropdown-text v-if="statuses.length === 0" class="text-center p-0"> Loading...</b-dropdown-text>
                  <b-dropdown-item v-for="(item, index_2) in statuses" :key="index_2" class="p-0"
                    >{{ item.status }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-nav>

              <b-badge v-if="task['worker_type']" class="font-small-2 text-uppercase" variant="light-secondary">
                {{ windowWidth < 768 ? task['worker_type'][0] : task['worker_type'] }}
              </b-badge>

              <small v-if="task.due_date" class="text-nowrap text-muted">
                <font-ai :icon="'fa-regular fa-clock'" />
                {{ formatDueDate(task.due_date) }}
              </small>

              <div class="pr-1">
                <b-avatar
                  v-if="task.assigned !== null"
                  v-tippy="{content: task.assigned.title, delay: [300, 0]}"
                  :src="task.assigned.avatar"
                  :style="formatColor($store.state.osBoard.users.find(el => el.id === task.assigned.id || null))"
                  :text="avatarText(task.assigned.title)"
                  :title="task.assigned.title"
                  size="28"
                />
                <b-avatar v-else size="32" variant="light-secondary">
                  <feather-icon icon="UserIcon" size="14" />
                </b-avatar>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <vue-simple-context-menu
      ref="vueSimpleContextMenu"
      :options="menuData"
      element-id="mySecondMenu"
      @option-clicked="optionClicked"
    >
    </vue-simple-context-menu>

    <!-- Task Handler -->
    <tasks-task-handler-sidebar
      ref="taskHandlerSidebar"
      v-model="isTaskHandlerSidebarActive"
      :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
      :status="status"
      :task="task"
      :task-type="taskType"
      @clear-task-data="clearTaskData"
      @remove-task="removeTask"
      @add-task="addTask"
      @update-task="updateTask"
    />

    <TaskView />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <tasks-left-sidebar
        :class="{show: mqShallShowLeftSidebar}"
        :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
        :is-task-sidebar-active="mqShallShowLeftSidebar"
        :task-tags="taskTags"
        :task-type="taskTypes"
        @create-task="createdNewTask"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

    <b-modal v-model="showModalChart" centered hide-footer hide-header hide-header-close>
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="mb-0 text-body">{{ $t('Analytics') }}</h4>
        <div class="d-flex align-items-center">
          <font-ai
            v-tippy="{content: $t('Close'), delay: [500, 0]}"
            class="text-secondary ml-75 cursor-pointer outline-none text-primary-hover"
            icon="fa-solid fa-xmark"
            @click="showModalChart = false"
          />
        </div>
      </div>
      <hr class="mt-75" />
      <b-table :fields="fields" :items="items" borderless hover responsive="sm" sticky-header></b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownText,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  BNav,
  BSpinner,
  BTable,
  VBTooltip,
} from 'bootstrap-vue';
import TasksLeftSidebar from '@/views/Tasks/TasksLeftSidebar';
import TasksService from '@/services/tasks.service';
import moment from 'moment';
import BoardService from '@/services/board.service';
import ProjectsService from '@/services/projects.service';
import UserService from '@/services/user.service';
import AuthService from '@/services/auth.service';
import {avatarText} from '@core/utils/filter';
import Ripple from 'vue-ripple-directive';
import TasksTaskHandlerSidebar from '@/views/Tasks/TasksTaskHandlerSidebar.vue';
import createDataSelectAction from 'echarts/src/action/createDataSelectAction';
import {colorStatus, formatColor, formatEstimate, getStatusesForProject} from '@/helpers/handlers';
import contextMenu from '@/mixins/mixContextMenu';
import {eventBus} from '@/main';
import OsLoader from '@/components/OsLoader.vue';
import TaskView from '@/views/Tasks/TaskView.vue';

export default {
  mixins: [contextMenu],
  components: {
    TaskView,
    OsLoader,
    BModal,
    TasksLeftSidebar,
    TasksTaskHandlerSidebar,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BDropdownText,
    BBadge,
    BAvatar,
    BNav,
    BSpinner,
    BTable,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      fields: [
        {key: 'last_name', sortable: true},
        {key: 'first_name', sortable: true},
        {key: 'age', sortable: true},
        {key: 'isActive', sortable: false},
      ],
      items: [
        {isActive: true, age: 40, first_name: 'Dickerson', last_name: 'Macdonald'},
        {isActive: false, age: 21, first_name: 'Larsen', last_name: 'Shaw'},
        {isActive: false, age: 89, first_name: 'Geneva', last_name: 'Wilson'},
        {isActive: true, age: 38, first_name: 'Jami', last_name: 'Carney'},
      ],
      showModalChart: false,
      searchQuery: '',
      statusInputQuery: true,
      tasksActual: [],
      filter: {
        name: 'tasks',
      },
      taskTags: [
        {title: 'Team', color: 'primary', route: {name: 'tasks-tag', params: {tag: 'team'}}},
        {title: 'Low', color: 'success', route: {name: 'tasks-tag', params: {tag: 'low'}}},
        {title: 'Medium', color: 'warning', route: {name: 'tasks-tag', params: {tag: 'medium'}}},
        {title: 'High', color: 'danger', route: {name: 'tasks-tag', params: {tag: 'high'}}},
        {title: 'Update', color: 'info', route: {name: 'tasks-tag', params: {tag: 'update'}}},
      ],
      taskTypes: [
        {title: 'Epic', color: 'info', route: {name: 'tasks-type', params: {type: 'epic'}}},
        {title: 'Task', color: 'primary', route: {name: 'tasks-type', params: {type: 'task'}}},
        {title: 'Bug', color: 'danger', route: {name: 'tasks-type', params: {type: 'bug'}}},
      ],
      taskType: [
        {label: 'epic', value: 'Epic'},
        {label: 'task', value: 'Task'},
        {label: 'bug', value: 'Bug'},
      ],
      status: [],
      statuses: [],
      task: {
        id: null,
        subject: '',
        status: 'To Do',
        task_type: 'Task',
        start_date: moment().format('YYYY-MM-DD HH:mm'),
        due_date: moment().format('YYYY-MM-DD HH:mm'),
        due_time: '00:00',
        description: '',
        project: null,
        assigned: null,
        is_important: false,
        ordering: 0,
      },
      isTaskHandlerSidebarActive: false,
      mqShallShowLeftSidebar: false,
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.app.windowWidth;
    },
    tasks() {
      return this.$store.state.osBoard.tasks;
    },
    updateProject() {
      return this.$store.state.osBoard.selectedProject;
    },
    selectedSprint() {
      return this.$store.state.osBoard.selectedSprint;
    },
    filterTasks() {
      const user = this.$store.state.osBoard.user.id;
      switch (this.$route.params.filter) {
        case 'my-tasks':
          return this.tasksActual.filter(
            item =>
              this.isAssigned(item.assigned) === user &&
              !item.is_important &&
              !this.isCompleted(item) &&
              !item.is_deleted
          );
        case 'important':
          return this.tasksActual.filter(item => this.imAssigneeOrPerformer(item, user) && item.is_important);
        case 'inbox':
          return this.tasksActual.filter(item => this.imAssigneeOrPerformer(item, user) && item.is_inbox);
        case 'completed':
          return this.tasksActual.filter(item => this.imAssigneeOrPerformer(item, user) && this.isCompleted(item));
        case 'deleted':
          return this.tasksActual.filter(item => this.imAssigneeOrPerformer(item, user) && item.is_deleted);
      }

      if (this.$route.params.type === 'bug') {
        return this.tasksActual.filter(item => this.imAssigneeOrPerformer(item, user) && item.task_type === 'Bug');
      } else if (this.$route.params.type === 'task') {
        return this.tasksActual.filter(item => this.imAssigneeOrPerformer(item, user) && item.task_type === 'Task');
      } else if (this.$route.params.type === 'epic') {
        return this.tasksActual.filter(item => this.imAssigneeOrPerformer(item, user) && item.task_type === 'Epic');
      } else {
        return this.tasksActual.filter(
          item => this.imAssigneeOrPerformer(item, user) && !item.is_deleted && !item.is_inbox
        );
      }
    },
    placeholderQuery() {
      return this.statusInputQuery ? this.$t('Search task') : this.$t('Quick Task Creation');
    },
  },
  watch: {
    updateProject() {
      this.getTasks();
    },
    selectedSprint() {
      this.getTasks();
    },
    updateUser() {
      this.getTasks();
    },
    tasks() {
      this.tasksActual = this.tasks;
    },
  },
  mounted() {
    this.getTasks();
    UserService.getUsers();
    BoardService.getStatuses();
    ProjectsService.getProjects();

    AuthService.userGeoData();

    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        eventBus.$emit('update-timer');
      }
    });
  },
  methods: {
    formatEstimate,
    createDataSelectAction,
    colorStatus,
    getStatusesForProject,
    formatColor,
    getTasks() {
      TasksService.getTasks(this.$route.params.filter === 'inbox');
    },
    getColorPriority(task, arr) {
      if (task.priority === 'high' && arr.includes(task.priority)) return 'tw-bg-red-400';
      if (task.priority === 'normal' && arr.includes(task.priority)) return 'tw-bg-sky-400';
      if (task.priority === 'low' && arr.includes(task.priority)) return 'tw-bg-orange-300';
    },
    isCompleted(task) {
      return task.status !== null ? task.status.label === 'done' || task.status.label === 'release' : false;
    },
    isAssigned(assigned) {
      return assigned !== null ? assigned.id : null;
    },
    imAssigneeOrPerformer(item, user) {
      const {isMyTasks, isImPerformer} = this.$store.state.board;
      return (
        (isMyTasks ? this.isAssigned(item.assigned) === user : true) &&
        (isImPerformer ? this.isAssigned(item.performer) === user : true)
      );
    },
    addTask(next) {
      TasksService.createTask(this.task).then(r => {
        this.createSubtask(r.data.id, next);
      });
    },
    createSubtask(parentId, next, counter = 0) {
      const subtasks = this.$refs.taskHandlerSidebar.subtasks;
      if (subtasks.length > counter) {
        TasksService.createTask({
          parent: parentId,
          ...subtasks[counter],
        }).then(() => {
          this.$refs.taskHandlerSidebar.subtasks[counter].createdInSidebar = true;
          this.createSubtask(parentId, next, counter + 1);
        });
      } else {
        this.addDescription(parentId, next);
      }
    },
    addDescription(id, next) {
      TasksService.createDescription(id, {
        type: 'text',
        string: '',
        ordering: 0,
        img: {
          file: null,
          url: null,
          description: '',
        },
        todoList: [],
        code: {
          lang: '',
          code: '',
        },
      }).then(() => {
        this.isTaskHandlerSidebarActive = false;
        this.$refs.taskHandlerSidebar.createdTask = false;
        if (next) {
          this.$router.push({name: 'task', params: {id: id}});
        } else {
          this.getTasks();
        }
      });
    },
    updateTask() {
      TasksService.updateTask(this.task.id, {
        ...this.task,
        project: this.task.project !== null ? this.task.project.id : null,
        task_type: this.task.task_type.label,
        due_date: this.task.due_date,
        start_date: this.task.start_date,
        assigned: this.task.assigned.id,
        status: this.task.status.id,
        created_by: this.task.created_by.id,
      }).then(() => {
        this.isTaskHandlerSidebarActive = false;
        this.getTasks();
      });
    },
    clearTaskData() {
      this.task = {
        id: null,
        subject: '',
        status: 'To Do',
        task_type: 'Task',
        start_date: moment().format('YYYY-MM-DD HH:mm'),
        due_date: moment().format('YYYY-MM-DD HH:mm'),
        due_time: '00:00',
        description: '',
        project: null,
        assigned: null,
        tags: [],
        is_completed: false,
        is_deleted: false,
        is_important: false,
        is_inbox: false,
        created_at: null,
        created_by: null,
        ordering: 0,
        parent: null,
        sprint: null,
      };
    },
    formatDueDate(date) {
      moment.locale(this.$i18n.locale);
      if (this.$i18n.locale === 'en') {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('MMM DD');
      } else {
        return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD MMM');
      }
    },
    createdNewTask() {
      this.clearTaskData();
      this.isTaskHandlerSidebarActive = true;
      this.mqShallShowLeftSidebar = false;
    },
    avatarText,
    changeStatusInput(status) {
      this.statusInputQuery = status;
    },
    resolveTypeVariant(type) {
      if (type === 'Epic') return 'info';
      if (type === 'Task') return 'primary';
      if (type === 'Bug') return 'danger';
      return 'primary';
    },
    resolveAvatarVariant(tags) {
      if (tags.includes('high')) return 'primary';
      if (tags.includes('medium')) return 'warning';
      if (tags.includes('low')) return 'success';
      if (tags.includes('update')) return 'danger';
      if (tags.includes('team')) return 'info';
      return 'secondary';
    },
    handleTaskClick(taskData) {
      this.$router.push({name: 'task', params: {id: taskData.id}});
    },
    quickTaskCreation() {
      this.tasks.push({
        id: 10000000,
        subject: this.searchQuery,
        status: 'new',
        task_type: 'Task',
        start_date: moment().format('YYYY-MM-DD'),
        due_date: moment().format('YYYY-MM-DD'),
        due_time: '00:00',
        project: this.$store.state.osBoard.selectedProject,
        ordering: 0,
        is_inbox: true,
      });
      TasksService.createTask({
        subject: this.searchQuery,
        status: 'new',
        task_type: 'Task',
        due_date: moment().format('YYYY-MM-DD'),
        due_time: '00:00',
        project: this.$store.state.osBoard.selectedProject,
        ordering: 0,
        is_inbox: true,
      }).then(() => {
        this.getTasks();
      });
      this.searchQuery = '';
    },
    updateRouteQuery(val) {
      if (this.statusInputQuery) {
        this.tasksActual = this.tasks.filter(function (task) {
          if ((task.id + ' ' + task.subject).toLowerCase().indexOf(val.toLowerCase()) !== -1) {
            return task;
          }
        });
      }
    },
    changedImportant(task) {
      TasksService.updateStatusTaskIsImportant(task).then(() => {
        this.getTasks();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/libs/vue-context.scss';

.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import '~@core/scss/base/pages/app-todo-original.scss';

.table-tasks {
  //display: grid;
  //grid-template-columns: auto auto auto 1fr auto auto auto auto auto auto auto;

  &__subject {
    display: flex;
    flex: 1 1 0;
    min-width: 0;

    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__hover-visible {
    display: none;
  }

  > div {
    &:not(:nth-child(1)) {
      border-top: 1px solid #ebe9f1;
    }

    &:last-child {
      border-bottom: 1px solid #ebe9f1;
    }

    > a {
      display: flex;
      cursor: pointer;

      &:hover {
        background-color: #f6f8fa;

        .table-tasks__hover-visible {
          display: flex;
        }
      }

      > div {
        padding: 0.28rem 0;
        display: flex;
        align-items: center;
      }
    }
  }

  .status-button {
    .dropdown-toggle {
      padding: 0;
    }
  }

  &__row {
  }
}

.todo-task-list {
  overflow-y: auto;
}

.todo-application .content-area-wrapper {
  border-radius: 0.65rem;

  .todo-title-wrapper {
    color: unset;
  }
}

//.horizontal-layout.navbar-floating.footer-fixed .app-content .content-area-wrapper,
//.horizontal-layout.navbar-floating.footer-fixed .app-content .content-wrapper,
//.horizontal-layout.navbar-floating.footer-fixed .app-content .kanban-wrapper {
//  height: 100% !important;
//}

@media screen and (max-width: 992px) {
  .table-tasks > div > a {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    font-size: 12px;

    .table-tasks__subject {
      order: -1;
      flex: 1 0 100%;
      padding: 0.5rem 0.8rem 0 0.8rem !important;

      span {
        white-space: unset;
      }
    }

    > div {
      &:nth-child(1) {
        padding-left: 0.8rem !important;
      }

      &:nth-child(3) {
        flex: 1 1 0;
      }

      &:nth-child(5) {
        display: none;
      }

      &:last-child {
        padding-right: 0.8rem !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container-tasks {
    flex-direction: column;

    .tasks {
      background-color: #fff;
      box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
      border-radius: 0.8rem;

      .todo-task-list-wrapper {
        > div:first-child {
          margin-bottom: 0 !important;
        }
      }
    }

    .card-filter {
      width: 100% !important;

      .sidebar-menu-list {
        > img {
          display: none;
        }
      }
    }

    .todo-title-wrapper {
      flex-direction: column;
      //margin-left: 25px;

      .title-wrapper {
        align-items: flex-start !important;

        .custom-checkbox {
          margin-top: 1.5px;
        }
      }

      .todo-item-action {
        justify-content: flex-end !important;
        margin-top: 10px;
      }
    }

    .todo-item {
      align-items: flex-start !important;

      &:hover {
        > .todo-title-wrapper {
          margin-left: 0 !important;
        }
      }

      > div:first-child {
        top: 30px;
      }
    }
  }

  //html [data-col='1-column'].horizontal-layout.footer-fixed .app-content {
  //  padding: calc(3.05rem + 2rem) 0 0 0 !important;
  //
  //  > div:nth-child(3) {
  //    //margin-bottom: 0.5rem !important;
  //    //margin-left: 0.8rem;
  //
  //    > h1 {
  //      font-size: 22px;
  //    }
  //  }
  //}

  .todo-application .content-area-wrapper .content-right .app-fixed-search {
    border-radius: 0 !important;
    padding: 0;

    > .dropdown {
      display: flex;
      align-items: center;
    }
  }

  .horizontal-layout.navbar-floating.footer-fixed .app-content .content-area-wrapper,
  .horizontal-layout.navbar-floating.footer-fixed .app-content .kanban-wrapper {
    height: calc(100vh - 145px) !important;
    overflow: unset;
    border-radius: 0;
  }

  .todo-application .content-area-wrapper .content-right .todo-task-list-wrapper {
    height: calc(100% - 2.76rem);
  }

  .table-tasks > div > a > div:last-child {
    font-size: 12px;

    > span {
      width: 24px !important;
      height: 24px !important;
    }
  }
}
</style>
