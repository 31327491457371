<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      :visible="isTaskHandlerSidebarActive"
      backdrop
      bg-variant="white"
      body-class="tw-w-full"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="val => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{hide}">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <b-button
            v-if="task.id"
            :variant="task.isCompleted ? 'outline-success' : 'outline-secondary'"
            size="sm"
            @click="task.isCompleted = !task.isCompleted"
          >
            {{ task.isCompleted ? $t('Task completed') : $t('Complete task') }}
          </b-button>
          <h5 v-else class="mb-0">Add Task</h5>
          <div class="d-flex align-items-center">
            <label class="m-0 mr-50" text="">Inbox</label>
            <b-form-checkbox
              :checked="task.is_inbox"
              class="custom-inbox-checkbox"
              name="check-button"
              switch
              @change="task.is_inbox = !task.is_inbox"
            >
              <span class="switch-icon-left">
                <font-ai icon="fa-solid fa-inbox" />
              </span>
              <span class="switch-icon-right">
                <font-ai class="text-black-50" icon="fa-solid fa-xmark" />
              </span>
            </b-form-checkbox>
            <feather-icon
              v-show="task.id"
              class="cursor-pointer"
              icon="TrashIcon"
              @click="
                $emit('remove-task');
                hide();
              "
            />
            <feather-icon
              :class="{'text-warning': task.is_important}"
              class="ml-1 cursor-pointer"
              icon="StarIcon"
              size="16"
              @click="() => (task.is_important = !task.is_important)"
            />
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>

        <b-tabs class="px-2 pt-50">
          <b-tab active>
            <template #title>
              <feather-icon icon="EditIcon" />
              <span>{{ task.id ? 'Update' : 'Add ' }}</span>
            </template>
            <!-- Body -->
            <b-form-group label="Type" label-for="type">
              <validation-provider #default="{errors}" name="Type" rules="required">
                <v-select
                  id="type"
                  v-model="task.task_type"
                  :dir="$store.state.appConfig['isRTL'] ? 'rtl' : 'ltr'"
                  :options="taskType"
                  class="assignee-selector"
                  label="value"
                >
                  <template #option="{value}">
                    <span class="ml-50 d-inline-block align-middle"> {{ value }}</span>
                  </template>

                  <template #selected-option="{value}">
                    <span class="ml-50 d-inline-block align-middle"> {{ value }}</span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Title -->
            <b-form-group label="Title" label-for="task-title">
              <b-form-input id="task-title" v-model="task.subject" autofocus placeholder="Task Title" trim />
            </b-form-group>

            <!-- Project -->
            <b-form-group label="Project" label-for="project">
              <validation-provider #default="{errors}" name="Project" rules="required">
                <v-select
                  id="project"
                  v-model="task.project"
                  :dir="$store.state.appConfig['isRTL'] ? 'rtl' : 'ltr'"
                  :options="$store.state.osBoard.projects"
                  :state="errors.length > 0 ? false : null"
                  class="assignee-selector"
                  label="title"
                >
                  <template #option="{title}">
                    <span class="ml-50 d-inline-block align-middle"> {{ title }}</span>
                  </template>

                  <template #selected-option="{title}">
                    <span class="ml-50 d-inline-block align-middle"> {{ title }}</span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Status -->
            <b-form-group v-show="task.project !== null" label="Status" label-for="status">
              <validation-provider #default="{errors}" name="Status" rules="required">
                <v-select
                  id="status"
                  v-model="task.status"
                  :dir="$store.state.appConfig['isRTL'] ? 'rtl' : 'ltr'"
                  :disabled="task.project === null"
                  :options="statusOptions"
                  :state="errors.length > 0 ? false : null"
                  class="assignee-selector"
                  label="value"
                >
                  <template #option="{value}">
                    <span class="ml-50 d-inline-block align-middle"> {{ value }}</span>
                  </template>

                  <template #selected-option="{value}">
                    <span class="ml-50 d-inline-block align-middle"> {{ value }}</span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Assigned -->
            <b-form-group v-show="task.project !== null" label="Assigned" label-for="assigned">
              <validation-provider #default="{errors}" name="Assigned" rules="required">
                <v-select
                  v-model="task.assigned"
                  :dir="$store.state.appConfig['isRTL'] ? 'rtl' : 'ltr'"
                  :disabled="task.project === null"
                  :options="task.project === null ? [] : task.project.members"
                  :state="errors.length > 0 ? false : null"
                  class="assignee-selector"
                  input-id="assigned"
                  label="title"
                >
                  <template #option="{avatar, title}">
                    <b-avatar :src="avatar" :text="avatarText(title)" size="26" variant="light-primary" />
                    <span class="ml-50 d-inline-block align-middle"> {{ title }}</span>
                  </template>

                  <template #selected-option="{avatar, title}">
                    <b-avatar :src="avatar" :text="avatarText(title)" size="26" variant="light-primary" />
                    <span class="ml-50 d-inline-block align-middle"> {{ title }}</span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Start Date -->
            <div class="tw-flex tw-gap-4">
              <!-- Start Date -->
              <b-form-group class="mb-0 flex-grow-1" label="Date Start" label-for="date-start">
                <validation-provider #default="{errors}" name="Date Start">
                  <date-picker
                    v-model="task['start_date']"
                    :format="$i18n.locale === 'en' ? 'MM/DD/YYYY' : 'DD.MM.YYYY'"
                    :state="errors.length > 0 ? false : null"
                    class="w-100"
                    placeholder="Select date"
                    prefix-class="xmx"
                    type="date"
                    value-type="YYYY-MM-DD HH:mm"
                  >
                    <template v-slot:icon-calendar>
                      <font-ai icon="fa-regular fa-calendar" />
                    </template>
                    <template v-slot:icon-clear>
                      <font-ai icon="fa-solid fa-xmark" />
                    </template>
                  </date-picker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Due Date -->
              <b-form-group class="mb-0 flex-grow-1" label="Date End" label-for="date-end">
                <date-picker
                  v-model="task.due_date"
                  :format="$i18n.locale === 'en' ? 'MM/DD/YYYY' : 'DD.MM.YYYY'"
                  class="w-100"
                  placeholder="Select date"
                  prefix-class="xmx"
                  type="date"
                  value-type="YYYY-MM-DD HH:mm"
                >
                  <template v-slot:icon-calendar>
                    <font-ai icon="fa-regular fa-calendar" />
                  </template>
                  <template v-slot:icon-clear>
                    <font-ai icon="fa-solid fa-xmark" />
                  </template>
                </date-picker>
              </b-form-group>
            </div>

            <div v-show="task.project !== null" class="tw-mt-4">
              <p class="tw-mb-2 tw-text-sm tw-text-gray-500">Subtasks</p>
              <div class="tw-flex tw-flex-col tw-gap-4">
                <div
                  v-for="(subtask, index) in subtasks"
                  :key="index"
                  :class="{'tw-border-sky-500': subtask.createdInSidebar}"
                  class="tw-border-s-4 tw-pl-3 tw-border-gray-200 tw-rounded-s-lg tw-transition"
                >
                  <div class="tw-flex tw-gap-4">
                    <input
                      v-model="subtask.subject"
                      class="tw-px-3 tw-py-2 tw-rounded-lg tw-outline-0 tw-border tw-border-gray-300 tw-w-full focus:tw-border-sky-500 tw-transition"
                      placeholder="Task title"
                      type="text"
                    />

                    <button
                      class="tw-p-3 tw-border tw-border-red-200 tw-rounded-lg hover:tw-bg-red-50"
                      @click="subtasks.splice(index, 1)"
                    >
                      <svg
                        class="tw-w-4 tw-h-4 tw-text-red-400"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </button>
                  </div>
                  <div class="tw-mt-4 tw-flex tw-gap-4">
                    <v-select
                      v-model="subtask.assigned"
                      :dir="$store.state.appConfig['isRTL'] ? 'rtl' : 'ltr'"
                      :options="task.project === null ? [] : task.project.members"
                      class="assignee-selector tw-w-3/5"
                      input-id="assigned"
                      label="title"
                      placeholder="Assigned"
                    >
                      <template #option="{avatar, title}">
                        <b-avatar :src="avatar" :text="avatarText(title)" size="26" variant="light-primary" />
                        <span class="ml-50 d-inline-block align-middle"> {{ title }}</span>
                      </template>

                      <template #selected-option="{avatar, title}">
                        <b-avatar :src="avatar" :text="avatarText(title)" size="26" variant="light-primary" />
                        <span class="ml-50 d-inline-block align-middle"> {{ title }}</span>
                      </template>
                    </v-select>

                    <date-picker
                      v-model="subtask.due_date"
                      :format="$i18n.locale === 'en' ? 'MM/DD/YYYY' : 'DD.MM.YYYY'"
                      class="tw-w-2/5"
                      placeholder="Due date"
                      prefix-class="xmx"
                      type="date"
                      value-type="YYYY-MM-DD HH:mm"
                    >
                      <template v-slot:icon-calendar>
                        <font-ai icon="fa-regular fa-calendar" />
                      </template>
                      <template v-slot:icon-clear>
                        <font-ai icon="fa-solid fa-xmark" />
                      </template>
                    </date-picker>
                  </div>
                </div>
              </div>
              <button
                :class="{'tw-mt-2': subtasks.length === 0}"
                class="tw-mt-4 tw-flex tw-items-center tw-bg-gray-100 tw-text-gray-600 tw-px-3 tw-py-2 tw-rounded-lg hover:tw-bg-gray-200 tw-border tw-border-gray-100 hover:tw-border-gray-300 tw-transition"
                @click.stop.prevent="addSubtask"
              >
                <svg
                  class="tw-h-4 tw-mr-2 tw-text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="3"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 4.5v15m7.5-7.5h-15" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Add subtask
              </button>
            </div>

            <!-- Form Actions -->
            <div class="d-flex my-2">
              <button
                v-if="!createdTask"
                class="tw-mr-2 tw-rounded-lg tw-bg-sky-500 tw-px-6 tw-py-3 tw-text-white hover:tw-bg-sky-600 tw-transition active:tw-bg-sky-700"
                @click="onSubmit(false)"
              >
                {{ task.id ? 'Update' : 'Add' }}
              </button>
              <button
                class="tw-rounded-lg tw-border tw-border-sky-500 tw-text-sky-500 tw-px-6 tw-py-3 hover:tw-border-sky-600 hover:tw-text-sky-600 tw-transition active:tw-text-sky-700 active:tw-bg-sky-100"
                @click="onSubmit(true)"
              >
                <template v-if="!createdTask">
                  {{ task.id ? 'Update and continue' : 'Add and continue' }}
                </template>
                <template v-else>
                  <os-loader :width="30" />
                </template>
              </button>
            </div>
          </b-tab>
          <b-tab v-if="task.id">
            <template #title>
              <feather-icon icon="ActivityIcon" />
              <span>Activity</span>
            </template>

            <TaskNotes :task-id="task.id" />
          </b-tab>
        </b-tabs>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {BAvatar, BButton, BFormCheckbox, BFormGroup, BFormInput, BSidebar, BTab, BTabs, VBTooltip} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import {ValidationProvider} from 'vee-validate';
import {email, required, url} from '@validations';
import BoardService from '@/services/board.service';
import {formatTitle} from '@/assets/scripts/scripts';
import TaskNotes from '@/components/Task/TaskNotes';
import DatePicker from 'vue2-datepicker';
import OsLoader from '@/components/OsLoader.vue';

export default {
  components: {
    OsLoader,
    // BSV
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BAvatar,
    BTabs,
    BTab,
    BFormCheckbox,
    TaskNotes,
    DatePicker,

    // 3rd party packages
    vSelect,

    // Form Validation
    ValidationProvider,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    taskType: {
      type: Array,
      required: true,
    },
    status: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      statusOptions: [],
      subtasks: [],
      tagOptions: [
        {label: 'Team', value: 'team'},
        {label: 'Low', value: 'low'},
        {label: 'Medium', value: 'medium'},
        {label: 'High', value: 'high'},
        {label: 'Update', value: 'update'},
      ],
      createdTask: false,
    };
  },
  computed: {
    updateProject() {
      return this.task.project;
    },
  },
  watch: {
    updateProject() {
      this.taskStatuses();
    },
  },
  mounted() {
    this.taskStatuses();
  },
  methods: {
    onSubmit(statusContinue) {
      this.createdTask = true;
      if (this.task.id) this.$emit('update-task', this.task);
      else this.$emit('add-task', statusContinue);
    },
    addSubtask() {
      this.subtasks.push({
        subject: this.task.subject,
        project: this.task.project,
        assigned: null,
        due_date: null,
        createdInSidebar: false,
      });
    },
    avatarText(value) {
      if (!value) return '';
      const nameArray = value.split(' ');
      return nameArray.map(word => word.charAt(0).toUpperCase()).join('');
    },
    clearForm() {
      this.$emit('clear-task-data');
    },
    onContext(ctx) {
      this.task.due_date = ctx.selectedYMD;
    },
    onContextTimeEnd(ctx) {
      this.task.due_time = ctx.formatted;
    },
    taskStatuses() {
      if (this.task.project) {
        BoardService.getProjectStatusesForTask(this.task.project.id).then(r => {
          this.statusOptions = r.data.map(status => {
            return {
              id: status.id,
              value: formatTitle(status.status),
              label: status.status,
            };
          });
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

$namespace: 'xmx'; // change the 'mx' to 'xmx'. then <date-picker prefix-class="xmx" />

$default-color: #6f6c7c;
$primary-color: #0095e8;

@import '~vue2-datepicker/scss/index.scss';

.xmx-datepicker {
  width: 100%;

  .xmx-input {
    height: 38px;
    box-shadow: unset;

    &:hover {
      border-color: #d8d6de;
    }

    &:focus {
      border-color: #0095e8;
    }
  }
}

.b-form-timepicker {
  .dropdown-menu {
    min-width: 8rem;

    .b-time {
      min-width: 105px;
    }
  }
}

.assignee-selector {
  .vs__dropdown-menu {
    max-height: 160px;
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-radius: 0 0 $border-radius $border-radius;
  }
}

.inbox-checkbox {
  display: flex;
  align-items: center;
}

.custom-inbox-checkbox {
  margin-right: 5px;

  .custom-control-label .switch-text-left,
  .custom-control-label .switch-icon-left,
  .custom-control-label .switch-text-right,
  .custom-control-label .switch-icon-right {
    top: 2px;
  }

  .custom-control-label:after {
    top: 5px;
  }
}
</style>